module.exports = {
  defaultLocale: "__default",
  loadLocaleFrom: (lang, ns) => import(`./src/shared/locales/${lang}/${ns}.json`).then((m) => m.default),
  locales: ["__default", "en", "it", "es", "fr"],
  localesToIgnore: ["__default"],
  logBuild: false,
  pages: {
    "*": [
      "agent",
      "casavo-why",
      "common",
      "blog",
      "blog-post",
      "buy-house",
      "how-it-works",
      "home",
      "contact-us",
      "faq",
      "faqs",
      "guides",
      "press",
      "privacy",
      "sell-house",
      "legal-entities",
      "terms-and-conditions",
      "about-us",
      "mortgage",
      "perfect-match",
      "digital-signature",
      "digital-signature-privacy",
      "digital-signature-otp",
      "react-bricks",
      "menu",
      "common-new",
      "sell",
      "footer",
      "match",
      "compliance",
      "priority",
    ],
    "/": ["home"],
    "/about-us": ["about-us"],
    "/admin/editor": ["react-bricks"],
    "/blog": ["blog"],
    "/blog/[slug]": ["blog-post", "react-bricks", "contact-us", "home"],
    "/blog/category/[tag]": ["blog", "blog-post"],
    "/blog/category/[tag]/page/[number]": ["blog", "blog-post"],
    "/buy-house": ["buy-house"],
    "/contact-us": ["contact-us"],
    "/digital-signature": ["digital-signature"],
    "/digital-signature-otp": ["digital-signature-otp"],
    "/digital-signature-privacy": ["digital-signature-privacy"],
    "/faq": ["faq"],
    "/faq/[slug]": ["faq"],
    "/faqs": ["faqs"],
    "/faqs/[slug]": ["faqs"],
    "/how-it-works": ["how-it-works"],
    "/legal-entities": ["legal-entities"],
    "/mortgage": ["mortgage"],
    "/perfect-match": ["perfect-match"],
    "/press": ["press"],
    "/privacy-policy": ["privacy"],
    "/sell-house": ["sell-house"],
    "/terms-and-conditions": ["terms-and-conditions"],
  },
}
